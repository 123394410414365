var render = function () {
  var _vm$item;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    class: {
      open: _vm.visible
    },
    style: _vm.resolveBorderColorByAirline(_vm.item.airline),
    attrs: {
      "id": "card-flight-".concat(_vm.item.id),
      "no-body": ""
    },
    on: {
      "mouseenter": _vm.collapseOpen,
      "mouseleave": _vm.collapseClose
    }
  }, [_c('b-card-header', {
    staticClass: "p-0",
    class: "\n    ".concat(!_vm.visible ? 'collapsed' : '', "\n    ").concat(_vm.selectedTrip && _vm.selectedTrip.id === _vm.item.id || _vm.visible ? 'bg-light-warning' : ['VN1A'].includes(_vm.item.source) && _vm.item.isGroupedItineraryResponse ? 'bg-light-info' : '', "\n    "),
    attrs: {
      "aria-expanded": _vm.visible ? 'true' : 'false',
      "aria-controls": _vm.collapseItemID,
      "role": "tab",
      "data-toggle": "collapseflight"
    },
    on: {
      "click": function click($event) {
        return _vm.updateVisible(!_vm.visible);
      }
    }
  }, [_vm._t("header", [_c('div', {
    staticClass: "w-100"
  }, [_c('b-row', {
    class: "d-flex-between flex-md-nowrap ".concat(_vm.appBreakPoint !== 'xl' ? 'font-small-3' : ''),
    staticStyle: {
      "max-height": "55px"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "d-flex-center",
    attrs: {
      "cols": "2",
      "md": "1"
    }
  }, [_c('div', {
    style: "".concat(['1G', 'F1', 'AK', 'TH', '1B'].includes(_vm.item.source) ? "position: relative; box-sizing: border-box; overflow: hidden; width: 100%;" : '', " ")
  }, [_c('div', {
    class: "d-flex-center ml-1 ".concat(_vm.resolveBadgeSource(_vm.item.source, (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.contentSource))
  }, [_c('IAmLogoAirline', {
    attrs: {
      "airline": _vm.item.segments[0].operating === 'BL' ? _vm.item.segments[0].operating : _vm.item.airline.split(' | ')[0] || _vm.item.operator,
      "height": 40
    }
  })], 1)])]), _c('b-col', {
    staticClass: "d-flex flex-column justify-content-center align-items-center",
    attrs: {
      "cols": "1",
      "xl": "1"
    }
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true
      }
    }],
    staticClass: "font-weight-bolder align-center",
    attrs: {
      "title": _vm.item.segments.map(function (item) {
        return _vm.resolveAirlineFlightNumber(item.airline, item.flightNumber);
      }).join(' | ')
    }
  }, [_c('span', {
    staticClass: "text-airline text-nowrap fw-700",
    class: _vm.isMobileView ? '' : 'font-medium-2'
  }, [_vm._v(" " + _vm._s(_vm.resolveAirlineFlightNumber(_vm.item.segments[0].airline, _vm.item.segments[0].flightNumber)) + " ")])]), _vm.isMobileView && _vm.getShowAircraft ? _c('div', {
    staticClass: "text-center"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true
      }
    }],
    staticClass: "text-nowrap text-body fw-700",
    attrs: {
      "title": _vm.getAirCraftByIata(_vm.item.segments[0].airCraft) || _vm.item.segments[0].airCraft
    }
  }, [_vm._v(" " + _vm._s(_vm.convertShortenAircraft(_vm.item.segments[0].airCraft)) + " ")])]) : _vm._e()]), _c('b-col', {
    staticClass: "d-none d-md-block text-black text-center",
    attrs: {
      "md": "2"
    }
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary.top.window",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true,
        "top": true,
        "window": true
      }
    }],
    class: ['xs'].includes(_vm.appBreakPoint) ? 'font-small-1' : 'font-medium-1',
    attrs: {
      "title": "".concat(_vm.$t('flight.totalDuration'), ": ").concat(_vm.toHoursAndMinutes(_vm.item.duration))
    }
  }, [_c('span', {
    class: _vm.isBoldTextAppCollapseFlight ? 'fw-800' : ''
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.item.departure.at, _vm.item.departure.timezone).time))]), _vm._v(" - "), _c('span', {
    class: _vm.isBoldTextAppCollapseFlight ? 'font-weight-light' : ''
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.item.arrival.at, _vm.item.arrival.timezone).time))])])]), _c('b-col', {
    staticClass: "text-center",
    class: _vm.isMobileView ? '' : 'font-medium-2',
    attrs: {
      "cols": "5",
      "md": "3"
    }
  }, [_c('div', _vm._l(_vm.getTripNameBySegments(_vm.item), function (point, pointIndex) {
    return _c('span', {
      key: pointIndex
    }, [_c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.html.v-primary.top.window",
        modifiers: {
          "hover": true,
          "html": true,
          "v-primary": true,
          "top": true,
          "window": true
        }
      }],
      class: pointIndex !== 0 && pointIndex !== _vm.getTripNameBySegments(_vm.item).length - 1 ? "font-weight-light ".concat(_vm.isMobileView ? 'font-small-2' : 'font-medium-1') : _vm.isBoldTextAppCollapseFlight ? pointIndex === 0 ? 'fw-800' : 'fw-600' : '',
      attrs: {
        "title": _vm.isMobileView ? '' : "<div>".concat(pointIndex !== 0 && pointIndex !== _vm.getTripNameBySegments(_vm.item).length - 1 ? 'Điểm dừng:' : '', " ").concat(point.name, "</div>\n                  ").concat(point.transitTime ? "<div>".concat(_vm.$t('ticket.flightsInfo.transitTime'), ": ").concat(point.transitTime, "</div>") : '')
      }
    }, [_vm._v(" " + _vm._s(point.iataCode) + " ")]), pointIndex !== _vm.getTripNameBySegments(_vm.item).length - 1 ? _c('span', [_vm._v(" - ")]) : _vm._e()]);
  }), 0), _vm.isMobileView ? _c('div', {
    class: _vm.isMobileView ? 'font-weight-bold' : ''
  }, [_c('span', {
    class: _vm.isBoldTextAppCollapseFlight ? 'fw-800' : ''
  }, [_vm._v(_vm._s(_vm.convertISODateTime(_vm.item.departure.at, _vm.item.departure.timezone).time))]), _vm._v(" - "), _c('span', [_vm._v(_vm._s(_vm.convertISODateTime(_vm.item.arrival.at, _vm.item.arrival.timezone).time))])]) : _vm._e()]), _vm.getShowAircraft ? _c('b-col', {
    staticClass: "d-none d-md-inline",
    attrs: {
      "cols": "1"
    }
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true
      }
    }],
    staticClass: "text-nowrap font-weight-bold",
    class: _vm.isMobileView ? '' : 'font-medium-2',
    attrs: {
      "title": _vm.getAirCraftByIata(_vm.item.segments[0].airCraft) || _vm.item.segments[0].airCraft
    }
  }, [_vm._v(" " + _vm._s(_vm.convertShortenAircraft(_vm.item.segments[0].airCraft)) + " ")])]) : _vm._e(), _vm.getShowBookingClass ? _c('b-col', {
    staticClass: "text-warning font-weight-bold text-left d-none d-md-block text-truncate",
    class: _vm.isMobileView ? '' : 'font-medium-2',
    attrs: {
      "cols": "1"
    }
  }, [_c('div', {
    staticClass: "d-flex-center"
  }, [['INTERNAL'].includes(_vm.item.clientId) && ['VN1A'].includes(_vm.item.source) && !_vm.item.domestic ? [_c('b-img', {
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "src": require("@icons/luggage.svg")
    }
  })] : [_vm.getMinFareOptions(_vm.item.fareOptions).luggage !== null ? _c('div', [_vm.getMinFareOptions(_vm.item.fareOptions).luggage ? _c('b-img', {
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "src": require("@icons/luggage.svg")
    }
  }) : _c('b-img', {
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "src": require("@icons/no-luggage.svg")
    }
  })], 1) : _vm._e()], _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary.top.window",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true,
        "top": true,
        "window": true
      }
    }],
    attrs: {
      "title": _vm.getMinFareOptions(_vm.item.fareOptions).groupClassName
    }
  }, [_vm._v(" " + _vm._s(_vm.getMinFareOptions(_vm.item.fareOptions).bookingClassAndSeatAvail) + " ")])], 2)]) : _vm._e(), _c('b-col', {
    staticClass: "d-flex flex-column align-items-start justify-content-center",
    attrs: {
      "cols": "2"
    }
  }, [!_vm.getMinFareOptions(_vm.item.fareOptions).price ? _c('div', [_c('span', {
    staticClass: "text-danger",
    attrs: {
      "id": "flight.full"
    }
  }, [_vm._v(_vm._s(_vm.$t('flight.full')))])]) : _c('div', [_vm.getShowPrice !== 'HIDE_FARE' ? _c('div', {
    class: _vm.selectedTrip && _vm.selectedTrip.fareOptions && _vm.selectedTrip.fareOptions.id === _vm.getMinFareOptions(_vm.item.fareOptions).id ? 'text-success' : 'text-warning'
  }, [_c('div', {
    class: "text-danger ".concat(_vm.isBoldTextAppCollapseFlight ? 'fw-800' : 'fw-600', " d-flex-center\n                  ").concat(_vm.isMobileView ? ' font-small-4' : 'font-medium-2')
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getMinFareOptions(_vm.item.fareOptions).price)) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "d-flex-center"
  }, [_vm.getMinFareOptions(_vm.item.fareOptions).discount && _vm.getShowPrice !== 'HIDE_FARE' ? _c('del', {
    staticClass: "text-secondary font-weight-bolder position-relative",
    class: _vm.isMobileView ? 'font-small-2' : 'font-small-4'
  }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.getMinFareOptions(_vm.item.fareOptions).price + _vm.getMinFareOptions(_vm.item.fareOptions).discount)))])]) : _vm._e()]), _vm.isMobileView ? _c('div', {
    staticClass: "d-flex-center"
  }, [_vm.getShowBookingClass ? _c('div', {
    staticClass: "d-flex-center justify-content-end text-warning font-weight-bold",
    attrs: {
      "cols": "1"
    }
  }, [['INTERNAL'].includes(_vm.item.clientId) && ['VN1A'].includes(_vm.item.source) && !_vm.item.domestic ? [_c('b-img', {
    staticStyle: {
      "height": "16px"
    },
    attrs: {
      "src": require("@icons/luggage.svg")
    }
  })] : [_vm.getMinFareOptions(_vm.item.fareOptions).luggage !== null ? _c('div', [_vm.getMinFareOptions(_vm.item.fareOptions).luggage ? _c('b-img', {
    staticStyle: {
      "height": "16px"
    },
    attrs: {
      "src": require("@icons/luggage.svg")
    }
  }) : _c('b-img', {
    staticStyle: {
      "height": "16px"
    },
    attrs: {
      "src": require("@icons/no-luggage.svg")
    }
  })], 1) : _vm._e()], _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html.v-primary.top.window",
      modifiers: {
        "hover": true,
        "html": true,
        "v-primary": true,
        "top": true,
        "window": true
      }
    }],
    attrs: {
      "title": _vm.getMinFareOptions(_vm.item.fareOptions).groupClassName
    }
  }, [_vm._v(" " + _vm._s(_vm.getMinFareOptions(_vm.item.fareOptions).bookingClassAndSeatAvail) + " ")])], 2) : _vm._e(), _c('div', {
    staticClass: "fw-600 ml-50"
  }, [!_vm.visible ? _c('feather-icon', {
    attrs: {
      "icon": "ChevronDownIcon",
      "size": "17"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "ChevronUpIcon",
      "size": "17"
    }
  })], 1)]) : _vm._e()])]), _c('b-col', {
    class: _vm.isMobileView ? 'text-center' : '',
    attrs: {
      "cols": "1"
    }
  }, [_vm.item.fareOptions.length ? [_vm.selectedTrip && _vm.selectedTrip.id === _vm.item.id ? _c('feather-icon', {
    staticClass: "text-warning p-25",
    attrs: {
      "icon": "CheckCircleIcon",
      "size": ['xs'].includes(_vm.appBreakPoint) ? '22' : '28'
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleQuickChoose(null, _vm.index, _vm.item.source);
      }
    }
  }) : _c('feather-icon', {
    staticClass: "p-25 text-secondary",
    attrs: {
      "icon": "CircleIcon",
      "size": ['xs'].includes(_vm.appBreakPoint) ? '22' : '28'
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        _vm.handleQuickChoose(_vm.getMinFareOptions(_vm.item.fareOptions).id, _vm.index, _vm.item.source);
      }
    }
  })] : _vm._e()], 2)], 1), _vm.getFlightLegInfoList ? _c('div', {
    staticClass: "d-flex-center text-danger font-italic font-small-5 font-weight-normal fw-700"
  }, [['VN1A'].includes(_vm.item.source) ? [_vm._v(" " + _vm._s(_vm.$t('flight.flightHaveNumberStop', {
    num: "".concat(_vm.getFlightLegInfoList.split(',').length > 1 ? _vm.getFlightLegInfoList.split(',').length - 1 : 1)
  })) + " ")] : [_vm._v(" " + _vm._s(_vm.$t('flight.flightHaveStop')) + " " + _vm._s(_vm.getFlightLegInfoList) + " ")]], 2) : _vm._e()], 1)])], 2), _c('b-collapse', {
    attrs: {
      "id": _vm.collapseItemID,
      "accordion": _vm.accordion,
      "role": "tabpanel"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_vm._t("default")], 2), ['VN1A'].includes(_vm.item.source) && _vm.item.isGroupedItineraryResponse ? _c('b-tooltip', {
    attrs: {
      "target": "card-flight-".concat(_vm.item.id),
      "triggers": "hover focus",
      "boundary": "window",
      "variant": "info"
    }
  }, [_c('div', {
    staticClass: "fw-700 font-small-4"
  }, [_vm._v(" Giá kết hợp Vietnam Airlines ")])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }